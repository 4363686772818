import { capitalize, first, toUpper, trim, words } from "lodash-es"
import { z } from "zod"
import {
  AppleAppStoreUrlRegex,
  GooglePlayStoreUrlRegex,
} from "@store-platform/types"
import urlJoin from "url-join"

export const zodNameValidator = (name: "first" | "last") =>
  z
    .string({
      required_error: `${capitalize(name)} name is required`,
    })
    .trim()
    .regex(/^[a-z ,.'’‘-]+$/i, `Invalid ${name} name`)
    .min(2, `${capitalize(name)} name must be at least 2 characters`)

export const zodEmailValidator = z
  .string({
    required_error: "Email is required",
  })
  .email("Invalid email")
  .min(3, "Invalid email length")

export const zodPasswordValidator = z
  .string({
    required_error: "Password is required",
  })
  .min(6, "Password must be at least 6 character long")

export const zodUsernameValidator = z
  .string({
    required_error: "Username is required",
  })
  .min(4, "Username must be at least 4 character long")
  .max(64, "Username cannot be longer than 64 characters")
  .regex(
    /^[a-z0-9_]+$/,
    "Username can only contain lowercase letters, numbers, and underscores",
  )

export const zodUrlValidator = z
  .string({
    required_error: "URL is required",
  })
  .refine(
    (value) => {
      try {
        const url = new URL(value)
        return url.protocol === "https:"
      } catch {
        return true
      }
    },
    { message: "Only HTTPS URLs are allowed" },
  )
  .refine(
    (value) => {
      try {
        new URL(value)
        return true
      } catch {
        try {
          new URL(`https://${value}`)
          return true
        } catch {
          return false
        }
      }
    },
    { message: "Invalid URL" },
  )

export const nameInitials = (displayName?: string): string => {
  if (!displayName) return ""

  const nameSegments = words(toUpper(trim(displayName)))
  return nameSegments.length > 1
    ? nameSegments.slice(0, 2).map(first).join("")
    : first(nameSegments)?.substring(0, 2) || ""
}

export const plural = (
  count: number,
  noun: string,
  { suffix = "s", showCount = true } = {},
) => `${showCount ? count : ""} ${noun}${count !== 1 ? suffix : ""}`.trimStart()

export const isAlphanumeric = (str: string) =>
  /^[a-zA-Z0-9]+([ ]{1}[a-zA-Z0-9]+)*$/.test(str)

export const appendQueryParams = (
  _searchParams: { [key: string]: string } | URLSearchParams,
  params: Record<string, string | number | boolean | undefined>,
) => {
  const searchParams =
    typeof _searchParams === "object"
      ? new URLSearchParams(_searchParams)
      : _searchParams

  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) return
    searchParams.append(key, value.toString())
  })
}

export const toUrlSafeString = (input: string) => {
  return encodeURIComponent(input.replace(/\s+/g, "-"))
}

export const fromUrlSafeString = (input: string) => {
  return decodeURIComponent(input).replace(/-/g, " ")
}

export const toRelative = (date: Date) => {
  if (!isValidDate(date)) return ""

  const now = new Date()
  const diff = date.getTime() - now.getTime()
  const absDiff = Math.abs(diff)

  // Simple calculation for days, hours, minutes, and seconds
  const years = Math.floor(absDiff / (1000 * 60 * 60 * 24 * 365))
  const months = Math.floor(absDiff / (1000 * 60 * 60 * 24 * 30))
  const days = Math.floor(absDiff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((absDiff / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((absDiff / 1000 / 60) % 60)
  // const seconds = Math.floor((absDiff / 1000) % 60)

  const prefix = diff > 0 ? "in " : ""
  const suffix = diff < 0 ? " ago" : ""

  if (years > 0) return `${prefix}${plural(years, "year")}${suffix}`
  if (months > 0) return `${prefix}${plural(months, "month")}${suffix}`
  if (days > 0) return `${prefix}${plural(days, "day")}${suffix}`
  if (hours > 0) return `${prefix}${plural(hours, "hour")}${suffix}`
  if (minutes > 0) return `${prefix}${plural(minutes, "minute")}${suffix}`
  // if (seconds > 0) return `${prefix}${plural(seconds, "second")}${suffix}`

  return "just now"
}

export const isValidDate = (date: Date) => {
  return !isNaN(date.getTime())
}

export const isAppleAppStoreUrl = (url: string) =>
  AppleAppStoreUrlRegex.test(url)

export const isPlayStoreUrl = (url: string) => GooglePlayStoreUrlRegex.test(url)

export const isWindowsStoreUrl = (url: string) =>
  /^https?:\/\/apps\.microsoft\.com\/store\/detail\/.*/.test(url)

export const ensureProtocol = (url: string): string => {
  url = url.replace("http://", "")
  if (!url.startsWith("https://")) {
    url = `https://${url}`
  }
  return url
}

export const formatNumberKMB = (num: number): string => {
  switch (true) {
    case num >= 1_000_000_000:
      return (num / 1_000_000_000).toFixed(1) + "B"
    case num >= 1_000_000:
      return (num / 1_000_000).toFixed(1) + "M"
    case num >= 1_000:
      return (num / 1_000).toFixed(1) + "k"
    default:
      return num.toFixed(0)
  }
}

/**
 * @param {string} shareLink - link with share hash attached
 * @param {string} shareText - text to accompany share link
 * @returns {string} draft tweet link with share text and share link
 */
export const getTwitterShareLink = (
  shareLink: string,
  shareText?: string,
): string => {
  const path = "https://x.com/intent/tweet"
  const urlParam = encodeURIComponent(shareLink)

  // Don't use URLSearchParams here because it adds extra url encoding that breaks the draft tweet
  if (shareText) {
    const textParam = encodeURIComponent(shareText)
    return urlJoin(path, `?text=${textParam}&url=${urlParam}`)
  }
  return urlJoin(path, `?url=${urlParam}`)
}

export const getTextShareLink = (shareText: string) => {
  return `sms:&body=${shareText}`
}
